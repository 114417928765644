import revive_payload_client_MMywjPg4BU from "/usr/local/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.4.1_eslint@9.9.0_jiti@1.21.6__ioredis@5.4.1__fichskbmmmtyk6xlmfbv6wkslq/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_4EfoOXqefM from "/usr/local/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.4.1_eslint@9.9.0_jiti@1.21.6__ioredis@5.4.1__fichskbmmmtyk6xlmfbv6wkslq/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_YL2LLp7fOP from "/usr/local/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.4.1_eslint@9.9.0_jiti@1.21.6__ioredis@5.4.1__fichskbmmmtyk6xlmfbv6wkslq/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_VayHl8L2Gy from "/usr/local/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.4.1_eslint@9.9.0_jiti@1.21.6__ioredis@5.4.1__fichskbmmmtyk6xlmfbv6wkslq/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_y6qW8zeCn3 from "/usr/local/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.4.1_eslint@9.9.0_jiti@1.21.6__ioredis@5.4.1__fichskbmmmtyk6xlmfbv6wkslq/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_jNhId1522T from "/usr/local/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.4.1_eslint@9.9.0_jiti@1.21.6__ioredis@5.4.1__fichskbmmmtyk6xlmfbv6wkslq/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_LWjJyGG3rB from "/usr/local/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.4.1_eslint@9.9.0_jiti@1.21.6__ioredis@5.4.1__fichskbmmmtyk6xlmfbv6wkslq/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/usr/local/app/.nuxt/components.plugin.mjs";
import prefetch_client_clG4riuvzO from "/usr/local/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.4.1_eslint@9.9.0_jiti@1.21.6__ioredis@5.4.1__fichskbmmmtyk6xlmfbv6wkslq/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_maO3nvoKug from "/usr/local/app/node_modules/.pnpm/@nuxtjs+color-mode@3.4.4_magicast@0.3.4_rollup@4.21.0/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import documentDriven_vVP10Hsltv from "/usr/local/app/node_modules/.pnpm/@nuxt+content@2.13.2_ioredis@5.4.1_magicast@0.3.4_nuxt@3.12.4_@parcel+watcher@2.4.1_@types+no_piom2gr5zxqf3ibvo2jst3uvvq/node_modules/@nuxt/content/dist/runtime/legacy/plugins/documentDriven.js";
import nuxt_plugin_ld2VswwEt3 from "/usr/local/app/node_modules/.pnpm/nuxt-delay-hydration@1.3.6_magicast@0.3.4_rollup@4.21.0/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.js";
import provideState_yHiSGOrJAX from "/usr/local/app/node_modules/.pnpm/nuxt-graphql-middleware@4.0.0_@parcel+watcher@2.4.1_@types+node@22.4.1_graphql-tag@2.12.6_gra_lw52xryk6buln4rsvkkvwm6qou/node_modules/nuxt-graphql-middleware/dist/runtime/plugins/provideState.mjs";
import plugin_u8IpL2xeQj from "/usr/local/app/node_modules/.pnpm/@nuxtjs+device@3.1.1_magicast@0.3.4_rollup@4.21.0/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import i18n_M4LohMQiUC from "/usr/local/app/node_modules/.pnpm/@nuxtjs+i18n@8.3.1_magicast@0.3.4_rollup@4.21.0_vue@3.4.38_typescript@5.5.4_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_gYV6wTqXtp from "/usr/local/app/node_modules/.pnpm/@nuxt+image@1.7.0_ioredis@5.4.1_magicast@0.3.4_rollup@4.21.0/node_modules/@nuxt/image/dist/runtime/plugin.mjs";
import unocss_MzCDxu9LMj from "/usr/local/app/.nuxt/unocss.mjs";
import plugin_xDtleEcACi from "/usr/local/app/modules/gtm/runtime/plugin.ts";
import _0_graphqlConfig_yaUZblG674 from "/usr/local/app/plugins/0.graphqlConfig.ts";
import _1_auth_slaK5pfDbE from "/usr/local/app/plugins/1.auth.ts";
import _2_activeOrder_yYINElYs5H from "/usr/local/app/plugins/2.activeOrder.ts";
import collections_pRcEP3swaI from "/usr/local/app/plugins/collections.ts";
import foxentry_client_gcy3vwc779 from "/usr/local/app/plugins/foxentry.client.ts";
import scroll_client_HJUo2UKx9n from "/usr/local/app/plugins/scroll.client.ts";
import supportAgent_3y6YttNG9k from "/usr/local/app/plugins/supportAgent.ts";
import vee_validate_KcKlKmvCrJ from "/usr/local/app/plugins/vee-validate.ts";
export default [
  revive_payload_client_MMywjPg4BU,
  unhead_4EfoOXqefM,
  router_YL2LLp7fOP,
  payload_client_VayHl8L2Gy,
  navigation_repaint_client_y6qW8zeCn3,
  check_outdated_build_client_jNhId1522T,
  chunk_reload_client_LWjJyGG3rB,
  components_plugin_KR1HBZs4kY,
  prefetch_client_clG4riuvzO,
  plugin_client_maO3nvoKug,
  documentDriven_vVP10Hsltv,
  nuxt_plugin_ld2VswwEt3,
  provideState_yHiSGOrJAX,
  plugin_u8IpL2xeQj,
  i18n_M4LohMQiUC,
  plugin_gYV6wTqXtp,
  unocss_MzCDxu9LMj,
  plugin_xDtleEcACi,
  _0_graphqlConfig_yaUZblG674,
  _1_auth_slaK5pfDbE,
  _2_activeOrder_yYINElYs5H,
  collections_pRcEP3swaI,
  foxentry_client_gcy3vwc779,
  scroll_client_HJUo2UKx9n,
  supportAgent_3y6YttNG9k,
  vee_validate_KcKlKmvCrJ
]